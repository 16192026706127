@import '../../variables';

.metafield-modal-body {
  min-height: 454px;
  max-height: 454px;
  .Polaris-Modal-Section {
    padding: 0;
    .metafield-modal-container {
      .metafield-modal-searchbar {
        padding: 1.6rem 2rem;
      }
      .metafield-modal-tags {
        padding: 0 2rem;
        padding-bottom: 1.6rem;
        .Polaris-Tag {
          padding-right: 0.8rem;
          margin: 10px 10px 0 0;
        }
      }
      .metafield-modal-checkboxes {
        padding: 0 !important;
        border-top: $border-style-default;
        label.Polaris-Choice {
          display: flex;
          padding: 2.8rem 2rem;
          border-bottom: $border-style-default;
        }
      }
    }
  }
}

