.appOptions {
  display: inline-block;
  width: 40%;
  max-width: 300px;
  margin-right: 2em;
  margin-bottom: 2em;
  -webkit-box-shadow: 1px 2px 14px 1px #c4cdd5;
  box-shadow: 1px 2px 14px 1px #c4cdd5;
  border-radius: 6px;
}

.appOptions__title {
  padding-top: 2em;
  text-align: center;
}

.appOptions:hover {
  -webkit-box-shadow: 1px 2px 18px 1px #919ea9;
  box-shadow: 1px 2px 18px 1px #919ea9;
  text-decoration: none;
}

.appOptions__content {
  height: 100%;
  width: 100%;
  padding: 2em;
  text-align: center;
  color: rgb(63, 63, 63);
}

.appOptions__content:hover {
  text-decoration: none;
}

.appOptions__content--icon {
  font-size: 8em;
  text-align: center;
  padding-bottom: 0.5em;
  color: #dfe3e8;
}

.routeOptions {
  text-align: center;
  padding: 3em 0;
}

.version {
  text-align: center;
  padding-bottom: 0.5em;
  color: #434546;
 }
