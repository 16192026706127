.segmentedButtonLeft .Polaris-Button {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  
  .segmentedButtonRight .Polaris-Button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .segmentedButtonMiddle .Polaris-Button {
    border-radius: 0px;
  }