.Polaris-ButtonGroup {
    justify-content: flex-end;
}

.Polaris-TextField__Spinner {
    display: none
}

div.setWrapper {
    border: 0.1rem solid #dfe3e8;
    border-top: none;
    border-bottom: none;
    background: white;
}

div.setWrapper li.Polaris-ResourceList__ItemWrapper {
    border: none;
}

div.setWrapper li.Polaris-ResourceList__ItemWrapper:not(:last-child):after {
    content: 'OR';
    text-align: center;
    color: #212b36;
    font-weight: bolder;
    border: thin solid #dfe3e8;
    padding: 10px;
    margin: 10px 0 10px 50%;
    display: inline-block;
    margin-bottom: 5px;
}