.fulfillment__filter {

  .Polaris-ResourceList__FiltersWrapper {
    padding-left: 0;
    padding-right: 0;
  
    + .Polaris-ResourceList {
      border: none;
    }
  }

}

.Polaris-Select__Input {
  padding: 10px;
}