.Polaris-Popover {
  .Polaris-Scrollable--vertical {
    overflow-y: initial;
  }
}

.date-picker-cell {
    .bp3-table-cell {
      width: 100%;
      height: 100%;
      padding: 0 10px;
      .bp3-table-truncated-text {
        width: 100%;
        height: 100%;
        .date-picker-popover {
          width: 100%;
          height: 100%;
          .date-popover {
            width: 100%;
            height: 100%;
            .date-popover-target {
              width: 100%;
              height: 100%;
              span {
                padding-left: 20px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .date-picker-cal {
   overflow-y: visible;
   width: 274px;
   height: 100%;
   min-height: 260px;
   border: solid 10px white;
   text-align: right;

   .Polaris-Button--iconOnly {
     border: none;
     .Polaris-Icon {
       height: 1.5rem;
       width: 1.5rem;
     }
   }
   .DayPicker-Months {
     &:focus {
       outline: none !important;
     }
     .DayPicker-Caption {
       .bp3-html-select {
         width: 50%;
       }
     }
   }
 }
  
   .Polaris-DatePicker__Day:focus {
    box-shadow: none;
  }
