.bp3-table-cell {
  font-size: 14px;
}

.timePopover {
  .bp3-table-cell {
    width: 100%;
    height: 100%;
    .bp3-table-truncated-text {
      width: 100%;
      height: 100%;
      .bp3-popover-wrapper {
        width: 100%;
        height: 100%;
        .bp3-popover-target {
          width: 100%;
          height: 100%;
          span {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.fulfillment-table {
  .bp3-table-top-container {
    pointer-events: none;
    background-color: #f5f6f7;
    .bp3-table-selection-enabled {
      background-color: #f5f6f7 !important;
    }
    .bp3-table-resize-handle-target {
      pointer-events: initial;
    }
  }

  .bp3-table-quadrant.bp3-table-quadrant-main {
    height: 69vh;
    @media (max-width: 1700px) {
      height: 73vh;
    }
  }
} 
