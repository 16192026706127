.add-definition-div {
    display: flex;
    justify-content: flex-start;
  }
  
  .add-definition-div .button {
    margin: 20px;
  }
  
  .tag-div {
    margin: 10px;
  }
