
.thumbnail {
    vertical-align: middle;
    margin: 5px;
    display: inline-block;
    max-height:50px;
}

.verticalSpacing {
    margin-top:2em;
}

.headerSpacing {
    padding: 1.6rem 1.6rem 1.6rem 2rem;
}

.cellText {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    text-decoration: none;
    color: #182026;
}

.variableCellText {
    display: inline-block;
    vertical-align: middle;
    margin-left: 45px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}

.singleTextCell {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}

.singleTextCellWithButton {
    width: 90%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}

.pagination {
    text-align: center;
    margin: 2em 0;
}

.spinnerPosition {
    text-align: center;
    padding: 3em 0;
}

.popoverContent {
    padding: 2em;
    width: 100%;
    max-width: 275px;
} 

.popoverContent > * {
    vertical-align: bottom;
}

.popoverContentInput {
    width: 80%;
    display: inline-block;
}

.confirmPopoverButton {
    width: calc(20% - 15px);
    display: inline-block;
    margin-left: 10px;

}

.tagSpacing {
    margin: 10px 10px 10px 0;
    display: inline-block;
}

.emptySearch {
    padding: 60px 0px 32px 0px;
}