.Polaris-ResourceList-Item:hover {
  background-image: none;
  cursor: default;
}

.Polaris-ResourceList__ItemWrapper:focus {
  outline: none !important;
}

.Polaris-ResourceList-Item--focusedInner {
  background-image: none;
}

div.dragCursor:hover {
  cursor: grab;
}
